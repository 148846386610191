import React from 'react'
import "./index"
import Delivery from './components/Delivery'
import Feature from './components/Feature'
import Topnav from './components/Topnav'
import TopPicks from './components/TopPicks'
import Meal from "./components/Meal"
import ServiceBox from "./components/serviceBox/ServiceBox"
import Categories from './components/Categories'
import NewsLetter from './components/NewsLetter'
import Footer from './components/Footer'
import SecAbout from './components/secabout/SecAbout'
import "./Scroll/scrollEffect"
const App = () => {
  return (
    <div className="App">
      <Topnav/>
      <Feature/>
      <Delivery/>
      <SecAbout/>
      <TopPicks/>
      <Meal/>
      <ServiceBox/>
      <Categories/>
      <NewsLetter/>
      <Footer/>

    </div>
  )
}

export default App