



export const topPicks = [
    {
        id: 1,
        title: "shawarma",
        price: "$850",
    },
      {
        id: 2,
        title: "shawarma",
        price: "$850"
       
    },
    {
        id: 3,
        title: "shawarma",
        price: "$850"
        
    },
    {
        id: 4,
        title: "shawarma",
        price: "$850"
     
    },
    {
        id: 5,
        title: "shawarma",
        price: "$850"
  
    },
    {
        id: 6,
        title: "shawarma",
        price: "$850"
        
    },
    {
        id: 7,
        title: "shawarma",
        price: "$850"
       
    },
    {
        id: 8,
        title: "shawarma",
        price: "$850"
      
    },
    {
        id: 9,
        title: "shawarma",
        price: "$850"
       
    },
    {
        id: 10,
        title: "shawarma",
        price: "$850"
        
    },
   
];

export const mealData = [
    {
        id: 5,
        name: "cheeseless pizza",
        category: "pizza",
        
        price: "$200" 
    },
    {
        id: 6,
        name: "cheeseless pizza",
        category: "pizza",
       
        price: "$200" 
    },
    {
        id: 7,
        name: "cheeseless pizza",
        category: "pizza",
        
        price: "$200" 
    },
    {
        id: 8,
        name: "cheeseless pizza",
        category: "pizza",
        
        price: "$200" 
    },
    {
        id: 9,
        name: "cheeseless pizza",
        category: "chicken",
        
        price: "$200" 
    },
    {
        id: 10,
        name: "cheeseless pizza",
        category: "chicken",
        
        price: "$200" 
    },
    {
        id: 11,
        name: "cheeseless pizza",
        category: "chicken",
     
        price: "$200" 
    },
    {
        id: 12,
        name: "cheeseless pizza",
        category: "chicken",

        price: "$200" 
    },
    {
        id: 13,
        name: "cheeseless pizza",
        category: "salad",

        price: "$200" 
    },
    {
        id: 14,
        name: "cheeseless pizza",
        category: "salad",

        price: "$200" 
    },
    {
        id: 15,
        name: "cheeseless pizza",
        category: "salad",

        price: "$200" 
    },
    {
        id: 16,
        name: "cheeseless pizza",
        category: "salad",

        price: "$200" 
    }
];

export const categories = [
    {
        id: 1,
        name: "Burger",
        text: "Burger"
    },
    {
        id: 2,
        name: "Burger",
        text: "Vegetable"
    },
    {
        id: 3,
        name: "Burger",
        text: "Pizza"
    },
    {
        id: 4,
        name: "Burger",
        text: "Wings"
    },
    {
        id: 5,
        name: "Burger",
        text: "FastFood"
    },
    {
        id: 6,
        name: "Burger",
        text: "Sharwama"
    },
    {
        id: 7,
        name: "Burger",
        text: "Resotto"
    },
    {
        id: 8,
        name: "Burger",
        text: "CAKE"
    },
    {
        id: 9,
        name: "Burger",
        text: "Chicken"
    },
    {
        id: 10,
        name: "Burger",
        text: "IceCream"
    },
    {
        id: 11,
        name: "Burger",
        text: "Beef"
    },
    {
        id: 12,
        name: "Burger",
        text: "Apple Pie"
    }
];