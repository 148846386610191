import React from 'react'
import {topPicks} from "../data/data"
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import IMG1 from "../img/w3.jpg"

const TopPicks = () => {
  return (
    <>
        <h1 className='text-orange-500 text-2xl text-center py-2'> Top Picks</h1>

        <div className='reveal hidden lg:flex m-auto max-w[1520px] py-2 px-2'>

            <Splide options={{perPage: 4, gap: "0.5rem", drag: "free", arrows: false}}>
            {
                topPicks.map((item)=>{
                    return(
                        <SplideSlide key={item.id}>
                        <div className='rounded-3xl relative'>
                            <div className='absolute w-full h-full bg-black/50 rounded-3xl text-white'>
                                <p className='px-2 font-bold'>{item.title}</p>
                                <p className='px-2 text-1xl pt-4 text-green-600'>{item.price}</p> 
                                <button className='bg-[#009d3f] text-white mx-2 absolute bottom-4'>Add to cart</button>
                            </div>
                    <img className='h-[200px] w-full object-cover rounded-3xl cursor-pointer hover:scale-105 ease-out' src={IMG1} alt={item.name}/>
                        </div>
                        </SplideSlide>
                    )
                } )
            }
            </Splide>
        </div>
    </>
  )
}

export default TopPicks