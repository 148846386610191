import React from 'react'
import IMG1 from "../img/w2.jpg"


const Delivery = () => {
  return (
    <div className='w-full h-full py-16 px-4'>
        <h3 className='text-orange-500 text-2xl text-center'>Quick Delivery App</h3>
        <div className='w-full reveal py-10 px-10 mx-auto grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 rounded-2xl'>
            <img className='w-[500px] mx-auto my-4 hover:scale-105 ease-out' style={{borderRadius: '40% 30% 20% 50% / 35% 60% 15% 40%', boxShadow:".1rem .1rem 1rem gray"}} src={IMG1} alt="food"/>
            <div className='flex flex-col justify-center'>
                <p className='text-[#ffffff] font-bold'>Get The App</p>
                <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2 text-[#d52341e7]'>Limitless Convinience On Demand</h1>
                <p style={{fontWeight: 100, fontStyle: "italic", color: "grey"}}> Lorem ipsum, dolor sit amet consectetur adipisicing elit. Qui labore, explicabo eligendi blanditiis itaque suscipit, dolorem libero ut similique adipisci, porro alias vero quisquam unde. Lorem ipsum, dolor sit amet consectetur adipisicing elit. Hic dolores numquam ipsum consequuntur voluptate quia!  Lorem ipsum, dolor sit amet consectetur adipisicing elit.  </p>
                <button className='bg-[#00d153] text-[#fff] w-[200px] rounded-md font-medium my-6 mx-auto md:mx-0 py-3'>Get Started</button>
            </div>
        </div>
    </div>
  )
}

export default Delivery