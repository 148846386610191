import React, { useState } from 'react'
import {AiOutlineMenu, AiOutlineSearch, AiOutlineClose}from "react-icons/ai"
import {BsFillCartFill, BsPerson} from "react-icons/bs"
import {TbTruckReturn} from "react-icons/tb"
import {FaGoogleWallet} from "react-icons/fa"
import {MdHelp, MdOutlineFavorite} from "react-icons/md"

const Topnav = () => {

  const [sideNav, setSideNav] = useState(false)

  return (
    <div className='max-w-[1520px] mx-auto flex justify-between items-center p-4'>
     <div className='flex items-center'> 
       <div onClick={()=> setSideNav(!sideNav)} className='cursor-pointer'>
          <AiOutlineMenu size={25}/>
       </div>

       <h1 className='text-2xl sm:text-3xl lg:text-4xl px-2'>
        OTAKU
        <span className='text-[#d52341e7]'>WEB</span>
       </h1>

       <div className='hidden lg:flex items-center bg-gray-200 rounded-full p-1 text-[14px]'>
           <p className='bg-[#d52341e7] text-white rounded-full p-2 text-[14px]'>Free</p>
           <p className='p-2 text-bold'>Delivery</p>
       </div>
     </div>

     <div className='bg-gray-200 shadow-slate-700 rounded-full flex items-center px-2 w-[200p] sm:w-[400px] lg:w-[500px]'>
           <AiOutlineSearch size={25} className="text-[#00d153]"/>
           <input className='bg-transparent p-2 w-full focus:outline-none'
           type="text"
           placeholder='search meals'
           />
     </div>
     <button className="bg-[#00d153] text-white hidden md:flex items-center py-2 px-4 rounded-full">
      <BsFillCartFill size={20}/> Cart
     </button>

     {
      sideNav ? (
        <div className='bg-black/60 fixed w-full h-screen z-10 top-0 left-0'></div>
      ): ("")
     }

     <div className={sideNav ? 'fixed top-0 left-0 w-[300px] h-screen bg-white z-10 duration-300':
      'fixed top-0 left-[-100%] w-[300px] h-screen bg-white z-10 duration-300'} >
        <AiOutlineClose onClick={() => setSideNav(!sideNav)} size={25} className="absolute right-4 top-4 cursor-pointer"/>

      <h2 className='text-2xl p-4'>Yum <span className='font-bold text-[#d52341e7]'>Eats</span></h2>

      <nav>
         <ul className='flex flex-col p-4 text-grey-900'>
          <li className='text-xl py-4 flex'>
            <BsPerson size={25} className="mr-4 text-white bg-[#00d153] rounded-full"/>
            My Account
          </li>
          <li className='text-xl py-4 flex'>
            <TbTruckReturn size={25} className="mr-4 text-white bg-[#00d153] rounded-full"/>
            Delivery
          </li>
          <li className='text-xl py-4 flex'>
            <MdOutlineFavorite size={25} className="mr-4 text-white bg-[#00d153] rounded-full"/>
            My Favourite
          </li>
          <li className='text-xl py-4 flex'>
            <FaGoogleWallet size={25} className="mr-4 text-white bg-[#00d153] rounded-full"/>
            My Wallet
          </li>
          <li className='text-xl py-4 flex'>
            <MdHelp size={25} className="mr-4 text-white bg-[#00d153] rounded-full"/>
            Help!
          </li>
         </ul>
      </nav>

      </div>

    </div>
  )
}

export default Topnav