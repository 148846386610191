import React from 'react';
import "./servicebox.css";
import Card from "../../components/Card"
import data from "./data"

const about = () => {
    return (
      <section id='about'>
        <div className="reveal container about__container">
         <div className="about__right">
            <h2>About Me</h2>
            <div className="about__cards">
                 {
                  data.map(item => (
                    <Card key={item.id} className="about__card">
                      <span className="about__card-icon">{item.icon}</span>
                      <h5>{item.title}</h5>
                      <small>{item.desc}</small>
                    </Card>
                  ))
                }
            </div>
            <h3>Trust in our abilities</h3>
            <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Qui labore, explicabo eligendi blanditiis itaque suscipit, dolorem libero ut similique adipisci, porro alias vero quisquam unde. Lorem ipsum, dolor sit amet consectetur adipisicing elit. Hic dolores numquam ipsum consequuntur voluptate quia!
            </p>
         
          </div>
        </div>
      </section>
    )
  }
  
  export default about