import React from 'react'
import IMGCOVER from "../img/w2.jpg"


const Feature = () => {

    
  return (
    <div className='max-w-[1520px] h-[500px] w-full py-4 px-4 relative group'>
      <div className='w-full h-full rounded-2xl bg-center bg-cover duration-500'
        style={{backgroundImage: `url(${IMGCOVER})`}}>
          <div style={{textAlign:'center', paddingTop:'12%'}}>
           <h1 className='py-4 text-[#fff] text-6xl'>WELCOME TO OTAKUWEB</h1>
           <p className='text-italic text-1xl text-[#fff] text-center py-2'>lorem ipsum porro pointer easem conatur yes sfeic return new <b/>Promise conatur yes sfeic return new Promise</p>
             
          </div>
      </div>

  

    </div>
  )
}

export default Feature