import {FaAward} from "react-icons/fa"
import {TbBooks} from "react-icons/tb"
import {BiHappyHeartEyes} from "react-icons/bi"

const data = [
    {id: 1, icon: <FaAward/>, title: "Experience", desc: "5+ Years Working"},
    {id: 2, icon: <TbBooks/>, title: "Projects", desc: "100+ projects completed"},
    {id: 3, icon: <BiHappyHeartEyes/>, title: "Clients", desc: "80+ satisfied clients"},
    {id: 4, icon: <BiHappyHeartEyes/>, title: "Clients", desc: "80+ satisfied clients"}
]

export default data;