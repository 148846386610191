import React from 'react'
import IMG1 from "../../img/w2.jpg"
import IMG2 from "../../img/w3.jpg"
import IMG3 from "../../img/w4.jpg"
import IMG4 from "../../img/w5.jpg"
import "./secbout.css"


const SecAbout = () => {
  return (
    <section className="section about text-center" aria-labelledby="about-label" id="about">
        <div className="container reveal">

            <div className="about-content">
                <p className="label-2 section-subtitle" id="about-label">Our Story</p>
                <h2 className="headline-1 section-title">Every Flavour Tells A Story</h2>
                <p className="section-text">
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Qui labore, explicabo eligendi blanditiis itaque suscipit, dolorem libero ut similique adipisci, porro alias vero quisquam unde. Lorem ipsum, dolor sit amet consectetur adipisicing elit. Hic dolores numquam ipsum consequuntur voluptate quia!
                </p>
                <div className="contact-label">Book Through Call</div>
                <a href="tel:08136880649" class="body-1 contact-number hover-underline">08136880649</a>
                <button className='bg-[#00d153] text-[#ffff] w-[200px] rounded-md font-medium my-6 mx-auto md:mx-0 py-3'>Get Started</button>
            </div>

            <div className='menu' aria-label="menu-label" id="menu">
            <ul class="grid-list">
               
                <li>
                    <div className="menu-card hover:card">
                        <figure className="card-banner img-holder" style={{width: 100, height: 100}}>
                            <img src={IMG1} alt="stormy" width="100" height="100" class="img-cover"/>
                         </figure>
                        <div>
                            <div className="title-wrapper">
                                <h3 className="title-3">
                                    <a href="beef" className="card-title">Beef Salad</a>
                                </h3>

                                
                                <span className="span title-2">$40.00</span>
                            </div>
                            <p className="card-text label-1">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed, minima.
                            </p>
                        </div>
                        
                    </div>
                </li>

                <li>
                    <div className="menu-card hover:card">
                        <figure className="card-banner img-holder" style={{width: 100, height: 100}}>
                            <img src={IMG4} alt="stormy" width="100" height="100" class="img-cover"/>
                         </figure>
                        <div>
                            <div className="title-wrapper">
                                <h3 className="title-3">
                                    <a href="beef" className="card-title">Turkish Salad</a>
                                </h3>

                                
                                <span className="span title-2">$100.00</span>
                            </div>
                            <p className="card-text label-1">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed, minima.
                            </p>
                        </div>
                        
                    </div>
                </li> 

                <li>
                    <div className="menu-card hover:card">
                    <figure className="card-banner img-holder" style={{width: 100, height: 100}}>
                            <img src={IMG2} alt="cloudy" width="100" height="100" class="img-cover"/>
                         </figure>
                        <div>
                            <div className="title-wrapper">
                                <h3 className="title-3">
                                    <a href="butter" className="card-title">Butter Salad</a>
                                </h3>

                               
                                <span className="span title-2">$10.00</span>
                            </div>
                            <p className="card-text label-1">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed, minima.
                            </p>
                        </div>
                        
                    </div>
                </li>

                <li>
                    <div className="menu-card hover:card">
                        <figure className="card-banner img-holder" style={{width: 100, height: 100}}>
                            <img src={IMG3} alt="cloud" width="100" height="100" class="img-cover"/>
                         </figure>
                        <div>
                            <div className="title-wrapper">
                                <h3 className="title-3">
                                    <a href="cream" className="card-title">Cream Salad</a>
                                </h3>
       
                                <span className="span title-2">$39.00</span>
                            </div>
                            <p className="card-text label-1">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed, minima.
                            </p>
                        </div>
                        
                    </div>
                </li>

            </ul>                
            </div>

        </div>
      </section>
  )
}

export default SecAbout