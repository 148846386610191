import React, { useState } from 'react'
import { mealData } from "../data/data"
import IMG2 from "../img/w2.jpg"

const Meal = () => {
   const [foods, setFoods] = useState(mealData);
   const filterCat = (category)=>{
    setFoods(
      mealData.filter((item)=>{
        return item.category === category;
      })
    )
   }


  return (
    <div className='max-w-[1520px] m-auto px-4 py-12'>
        <h1 className='text-orange-500 text-2xl text-center py-2'>
            Our Meal
        </h1>
        <div className='reveal flex flex-col lg:flex-row justify-center'>
            <div className='flex justify-center md:justify-center'>
                <button 
                onClick={()=>setFoods(mealData)}
                className='m-1 border-[#00d153] text-white hover:bg-white hover:text-[#00d153] hover:border-[#00d153] bg-[#00d153]'>All</button>
                <button 
                onClick={()=>filterCat("pizza")}
                className='m-1 border-[#00d153] text-white hover:bg-white hover:text-[#00d153] hover:border-[#00d153] bg-[#00d153]'>Pizza</button>
                <button 
                onClick={()=>filterCat("chicken")}
                className='m-1 border-[#00d153] text-white hover:bg-white hover:text-[#00d153] hover:border-[#00d153] bg-[#00d153]'>Chicken</button>
                <button 
                onClick={()=>filterCat("salad")}
                className='m-1 border-[#00d153] text-white hover:bg-white hover:text-[#00d153] hover:border-[#00d153] bg-[#00d153]'>Salad</button>
            </div>
        </div>
        <div className='grid md:grid-cols-2 sm:grid-cols-1 lg:grid-cols-4 gap-6 py-4'>
           {
             foods.map((item)=>{
                return(
                    <div className='border-none hover:scale-105 duration-300' key={item.id}>
                    
                    <img src={IMG2} alt={item.name}
                    className='w-full h-[200px] object-cover rounded-lg'
                    />
                    <div className='flex justify-between py-2 px-2'>
                    <p className="text-green-700 font-bold">{item.name}</p>    
                    <p className='bg-[#00d153] h-18 w-18 rounded-full -mt-10 text-white border-8 border-white py-3 px-3 '>{item.price}</p>
                    </div>
                    <div className="pl-2 py-4 -mt-7">
                        <p className='text-indigo-600'>View More</p>
                    </div> 
                </div>
                )
              
             })
           }
        </div>
    </div>
  )
}

export default Meal