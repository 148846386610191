import React from 'react'
import { FaDribbble, FaFacebookSquare, FaGithubSquare, FaInstagram,FaTwitterSquare } from 'react-icons/fa'

const Footer = () => {
  return (
    <div className='max-w-[1520px] m-auto px-4 py-2 bg-[#24262b]'>
        <div className='reveal py-16 px-4 grid lg:grid-cols-3 gap-8 text-gray-300'>
            <div>
              <h1 className='w-full text-3xl font-bold text-[#d52341e7]'>OTAKUWEB</h1>
              <p>lorem enfie ewfhiu fiuehwi iuwdiw dwudaca cec c e uhfu9e ueshfuefhusecousehfu9sefuos 9useh usn ieh uoseusdh usehfush usv u u9hfiuosehfouseh useh</p>  
              <div className='flex justify-between md:w-[75%] my-6'>
              <FaDribbble size={30}/>
              <FaFacebookSquare size={30}/>
              <FaGithubSquare size={30}/>
              <FaInstagram size={30}/>
              <FaTwitterSquare size={30}/>    
             </div>  
            </div> 
            <div className='lg:col-span-2 flex justify-between mt-6'>
                <div>
                    <h6 className='font-medium text-[#9b9b9b]'>Location</h6>
                    <ul>
                        <li className='py-2 text-sm'>Japan</li>
                        <li className='py-2 text-sm'>Nigeria</li>
                        <li className='py-2 text-sm'>India</li>
                        <li className='py-2 text-sm'>Palestine</li>
                    </ul>
                </div>
                <div>
                    <h6 className='font-medium text-[#9b9b9b]'>Contact</h6>
                    <ul>
                        <li className='py-2 text-sm'>aw365598@gmail.com</li>
                        <li className='py-2 text-sm'>+234 813 688 0649</li>
                        <li className='py-2 text-sm'>Okaka Estate</li>
                        <li className='py-2 text-sm'>Yenagoa Bayelsa</li>
                    </ul>
                </div>
                <div>
                    <h6 className='font-medium text-[#9b9b9b]'>Address</h6>
                    <ul>
                        <li className='py-2 text-sm'>Okaka Estate Yenagoa</li>
                        <li className='py-2 text-sm'>Nigeria</li>
                    </ul>
                </div>
                <div>
                    <h6 className='font-medium text-[#9b9b9b]'>Services</h6>
                    <ul>
                        <li className='py-2 text-sm'>Home Chef</li>
                        <li className='py-2 text-sm'>Party Arrangements</li>
                        <li className='py-2 text-sm'>Event Hostings</li>
                        <li className='py-2 text-sm'>Birthday Parties</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer