import React from 'react'
import { categories } from '../data/data'


const Categories = () => {
  return (
    <div className='max-w-[1520px] m-auto px-4 py-3'>
        <h1 className='text-orange-500 text-2xl text-center py-2'>
            Trending Categories
        </h1>
        <div className='reveal grid grid-cols-2 md:grid-cols-6 gap-5 py-5 px-2'>
            {categories.map((item)=>{
                return(
                    <div className='p-4 flex justify-center items-center hover:scale-105 duration-300' key={item.id}>
                    <h1 className='font-bold text-orange-200 text-3xl'>{item.text}</h1>
                    </div>
                )
            })}
        </div>
    </div>
  )
}

export default Categories